
import { Button } from 'primereact/button';
import { TabPanel, TabView } from 'primereact/tabview';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FacturaInfo from './FacturaInfo';
import FacturaLineas from './FacturaLineas';
import { toast } from 'react-toastify';
import { saveAnalisisService, saveFicheroService } from '../services/analisisService';
const ResultadoAnalisis = ({datos, fichero})=>{    
    const [factura, setFactura] = useState();
    const analizado = useMemo(()=>{
        if (datos?.length){
            return datos[0]?.fields;
        }
        return null;
    },[datos]);
	
  
	const transformaLinea = useCallback((it, index,cif)=>{
		let cantidad = it?.value?.Quantity?.value;
		if (!cantidad || cantidad ===0){
			cantidad =1;
		}
		let codigo = it?.value?.ProductCode?.content;
		let ean = it?.value?.ProductCode?.content;;
		if ((cif==='NL864145561B01'|| cif==='LU19647148') ){
			codigo = 'COMISIONES';
			ean = 'COMISIONES';
		}
		let unitPrice = it?.value?.UnitPrice?.content?.replace('EUR','').replace('€','').replace(',','.').replace(' ','').trim();
		let total = it?.value?.Amount?.content
			?.replace('EUR', '')
			.replace('€', '')
			.replace(',', '.')
			.replace(' ', '')
			.trim();
	
		unitPrice = unitPrice?? total;
		total = total?? unitPrice;
		unitPrice = parseFloat(unitPrice);
		total = parseFloat(total);
		return ({
			id: index,
			productoNombre: it?.value?.Description?.content,
		//	productoNombreAdicional:  it?.value?.Description?.content,
			codigo,
			ean,
			importeBaseImponible: Math.abs(unitPrice),
			importeTotal: Math.abs(total),
			iva: 0,
			cantidad : (unitPrice<0 || total <0)? -cantidad: cantidad				
	});
},[]);

    const datosTransformados = useMemo(
			() => ({
				tipoDocumento: 'FACTURA',
				serie: 'ZAS',
				numDocumento: analizado?.InvoiceId?.content,
				referenciaDocumento: analizado?.InvoiceId?.content,
				fechaDocumento: analizado?.InvoiceDate?.value,
				proveedorNombre: analizado?.VendorAddressRecipient?.value ?? analizado?.VendorName?.value,
				proveedorCif: analizado?.VendorTaxId?.value,
				clienteNombre: analizado?.CustomerName?.value,
				clienteCif: analizado?.CustomerTaxId?.value,
				estado: 'ANALISIS',
				formaPago: analizado?.PaymentTerm?.content,
				importeBaseImponible: analizado?.SubTotal?.value?.amount,
				tipoIva: Math.round((analizado?.TotalTax?.value?.amount * 100) / analizado?.SubTotal?.value?.amount),
				importeIva: analizado?.TotalTax?.value?.amount,
				importeTotal: analizado?.InvoiceTotal?.value?.amount,
				fechaEscaneo: new Date(),
				items: analizado?.Items?.value?.map((it, index) => transformaLinea(it, index, analizado?.VendorTaxId?.value)),
			}),
			[analizado, transformaLinea]
		);

    useEffect(()=>{
        setFactura(datosTransformados);
    },[datosTransformados]);

      const guardarDatos = useCallback(async()=>{
		const datos = {
			cabecera: factura,
			lineas: factura?.items?.map((l) => ({
				...l,
				id: null,
				serie:factura.serie,
				tipoDocumento: factura?.tipoDocumento,
				numDocumento: factura?.numDocumento,
				fechaDocumento: factura?.fechaDocumento,
				proveedorNombre: factura?.proveedorNombre,
				proveedorCif: factura?.proveedorCif,
				clienteNombre: factura?.clienteNombre,
				clienteCif: factura?.clienteCif,
				fechaEscaneo: factura?.fechaEscaneo,
				tipoIva: factura?.tipoIva ?? 0,
			})),
		};
		const result = await saveAnalisisService(datos);
		if (!result.error){

			toast('Factura guardada correctamente');
		}else{
			toast.warn(`Error guardando datos ${result.mensaje}`);
		}
		// if (!result.error){
		// 	await saveFicheroService(fichero);
		// 	toast('Fichero guardados correctamente');
		// }else{
		// 	toast.warn(`Error guardando datos ${result.mensaje}`);
		// }
	//	console.log(datos);
    },[factura, fichero]);

    return (
			<div className='surface-section' style={{ fontSize: '11px' }}>
				<div className='font-medium text-3xl text-900 mb-3'>
					Factura de {factura?.proveedor}
					<Button label='Guardar' onClick={guardarDatos} />
				</div>
				<TabView panelContainerClassName='p-0'>
					<TabPanel header='Datos Factura'>
						<FacturaInfo factura={factura} setFactura={setFactura} />
					</TabPanel>
					<TabPanel header='Líneas Factura' contentClassName='p-0'>
						<FacturaLineas factura={factura} setFactura={setFactura} />
					</TabPanel>
				</TabView>
			</div>
		);
};

ResultadoAnalisis.propTypes = {
	datos: PropTypes.object,
	fichero: PropTypes.any
};

export default ResultadoAnalisis;