import { useContext } from "react";
import { useLocalStorage } from "./useLocalStorage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import AppContext from "../contexts/AppContext";
import { useQueryClient } from '@tanstack/react-query';

export const useAuthUser = () => {
	const { setItem } = useLocalStorage();
	const {user, setUser} = useContext(AppContext);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const login = (user,password) => {
		if (user==='BATMAN' && password==='donut'){
			const data = {
				user: 'BATMAN',
				nombre: 'Rafa Ruiz',
			};
			setItem('user', JSON.stringify(data));
			setUser(data);
			toast.error(`Bienvenido ${data.nombre}!`);
			navigate('/');
		}		
	};

	const logout = () => {
		setItem('user', null);
		setUser(null);
		queryClient.clear();
	};

	return { user, login, logout };
};
