import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useCallback } from "react";

const FacturaInfo = ({factura, setFactura})=>{

    const modificaDatos = useCallback((attr, value) => {
                setFactura({ ...factura, [attr]: value });
     },  [factura, setFactura]      );

				
				
				

    return (
			<>
				<ul className='list-none p-0 m-0'>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Tipo Doc</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.tipoDocumento}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.tipoDocumento,
								})}
								onChange={(e) => modificaDatos('tipoDocumento', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Nº Documento</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.numDocumento}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.numDocumento,
								})}
								onChange={(e) => modificaDatos('numDocumento', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Referencia Doc</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.referenciaDocumento}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.referenciaDocumento,
								})}
								onChange={(e) => modificaDatos('referenciaDocumento', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Fecha Documento</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.fechaDocumento}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.fechaDocumento,
								})}
								onChange={(e) => modificaDatos('fechaDocumento', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Proveedor</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.proveedorNombre}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.proveedorNombre,
								})}
								onChange={(e) => modificaDatos('proveedorNombre', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Proveedor CIF</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.proveedorCif}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.proveedorCif,
								})}
								onChange={(e) => modificaDatos('proveedorCif', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Cliente</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.clienteNombre}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.clienteNombre,
								})}
								onChange={(e) => modificaDatos('clienteNombre', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Cliente CIF</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.clienteCif}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.clienteCif,
								})}
								onChange={(e) => modificaDatos('clienteCif', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Estado</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.estado}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.estado,
								})}
								onChange={(e) => modificaDatos('estado', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Forma Pago</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.formaPago}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.formaPago,
								})}
								onChange={(e) => modificaDatos('formaPago', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Importe Base Imponible</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.importeBaseImponible}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.importeBaseImponible,
								})}
								onChange={(e) => modificaDatos('importeBaseImponible', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Tipo IVA</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.tipoIva}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.tipoIva,
								})}
								onChange={(e) => modificaDatos('tipoIva', e.target.value)}
							/>
						</div>
					</li>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Importe IVA</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.importeIva}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.importeIva,
								})}
								onChange={(e) => modificaDatos('importeIva', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'>Importe Total</div>
						<div className='text-900 w-4 '>
							<InputText
								value={factura?.importeTotal}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.importeTotal,
								})}
								onChange={(e) => modificaDatos('importeTotal', e.target.value)}
							/>
						</div>
					</li>
				</ul>
				<hr />
				<ul className='list-none p-0 m-0'>
					<li className='flex align-items-center p-1 border-top-1 surface-border flex-wrap'>
						<div className='text-500 w-2 font-medium'>Serie</div>
						<div className='text-900 w-4'>
							<InputText
								value={factura?.serie}
								className={classNames('w-full p-1 text-xs', {
									'border-red-500': !factura?.serie,
								})}
								onChange={(e) => modificaDatos('serie', e.target.value)}
							/>
						</div>
						<div className='text-500 w-2 font-medium'></div>
						<div className='text-900 w-4 '></div>
					</li>
				</ul>
			</>
		);
}

export default FacturaInfo;