import axios from "axios";

//const WS_URI = 'http://192.168.1.169:8483/imairecognizer';
const WS_URI = 'http://imasat.com:8483/imairecognizer'

//const WS_URI = 'http://192.168.1.48:8483/imairecognizer/';
export const getAnalisisService = (file) => {
	return axios.post(`${WS_URI}/convierteFactura`, file).then((response) => response?.data);
};

export const saveAnalisisService = (factura) => {
	return axios.post(`${WS_URI}/saveDatosFactura`, factura).then((response) => response?.data);
};

export const saveFicheroService = (factura) => {
	return axios.post(`${WS_URI}/subeFacturaFichero`, factura).then((response) => response?.data);
};