import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useCallback } from "react";

const FacturaLineas = ({factura, setFactura})=>{
    
    const changeLinea = useCallback((row, col, val) => {
        const nuevasLineas = factura.items.map((l) => {
            if (l.id === row.id) {
                return { ...l, [col.field]: val };
            }
            return { ...l };
        });
        setFactura({ ...factura, items: nuevasLineas });
	},	[factura, setFactura]);

    
    const renderText = useCallback(
			(row, col) => (
				<InputText
					value={row[col.field]}
					className={classNames('w-full p-1 text-xs', {
						'border-red-500': !row[col.field] || row[col.field]?.length > 95,
					})}
					onChange={(e) => {
						changeLinea(row, col, e.target.value);
					}}
				/>
			),
			[changeLinea]
		);
        const renderEan = useCallback(
					(row, col) => (
						<InputText
							value={row[col.field]}
							className={classNames('w-full p-1 text-xs', {
								'border-red-500': !row[col.field] || row[col.field]?.length >13,
							})}
							onChange={(e) => {
								changeLinea(row, col, e.target.value);
							}}
						/>
					),
					[changeLinea]
				);

    const renderCantidad = useCallback((row, col) => {
        const cantidad = row[col.field];
           return  <InputText
               className={classNames('w-full p-1 text-xs', {
                                                'border-red-500': !cantidad || cantidad === 'NaN',  
                                            })}
                value={cantidad}
                onChange={(e) => {
                    changeLinea(row, col, e.target.value);
                }}
            />
            },  [changeLinea]  );
     const deleteLinea = useCallback(row=>{
        const nuevasLineas =factura.items.filter(l=>l.id!==row.id);
        setFactura({ ...factura, items: nuevasLineas });
    },[factura, setFactura]);

    const renderBorra = useCallback(
			(row) => (
                <i className='pi pi-times cursor-pointer' 
                    onClick={() => deleteLinea(row)}/>
			),
			[deleteLinea]
		);

    const addLinea = useCallback(()=>{
        const nuevasLineas = [...factura.items];
        nuevasLineas.push({id: factura?.items?.length+1})
        setFactura({ ...factura, items: nuevasLineas });
    },[factura, setFactura]);



    return (
			<div className='col-12'>
				<Button
					size='small'
					icon='pi pi-plus'
					severity='warning'
					label='Nueva linea'
					className='p-1'
					aria-label='Añadir'
					onClick={addLinea}
				/>
				<DataTable value={factura?.items} size='small'>
					<Column field='' header='' body={renderBorra}></Column>
					<Column field='codigo' header='Código' body={renderText} style={{ maxWidth: '80px' }}></Column>
					<Column field='ean' header='Ean' body={renderEan} style={{ maxWidth: '80px' }}></Column>
					<Column field='productoNombre' header='Nombre' body={renderText}></Column>
					{/* <Column field='productoNombreAdicional' header='Nombre ad' body={renderText}></Column> */}
					<Column field='cantidad' header='Ud.' body={renderCantidad} style={{ maxWidth: '50px' }}></Column>
					<Column
						field='importeBaseImponible'
						header='Precio'
						body={renderCantidad}
						style={{ maxWidth: '80px' }}
					></Column>
					<Column field='importeTotal' header='Total ' body={renderCantidad} style={{ maxWidth: '80px' }}></Column>
				</DataTable>
			</div>
		);
};

export default FacturaLineas;
